<template>
   <div id="chart"></div>
</template>

<script>
import * as d3 from "d3";

export default {
    name: "CategoryChart",
    props: {
        categories: Array,
    },
    data: () => ({
        chart: null,
    }),
    computed: {
        fill: function() {
            return this.$vuetify.theme.themes.light.primary
        },
        highlightFill: function() {
            return this.$vuetify.theme.themes.light.secondary
        }
    },
    watch: {
        categories: function(val) {
            // TODO - should update the chart rather than destroy...
            console.log("watcher")
            if ( this.chart != null ) this.chart.remove()
            this.renderChart(val)
        }
    },
    mounted: function() {
        this.renderChart(this.categories)
    },
    methods: {
        renderChart: function(chartData) {
            var margin = {top: 30, right: 30, bottom: 50, left: 80};
            const svg_width = 500;
            const svg_height = 600;
            const chart_width = svg_width - margin.left - margin.right;
            const chart_height = svg_height - margin.top - margin.bottom;
            const fill = this.fill;
            const highlightFill = this.highlightFill;

            console.log("rendering")
            console.log(chartData)

            const svg = d3
                .select("#chart")
                .append("svg")
                .attr("width", svg_width)
                .attr("height", svg_height);

            this.chart = svg

            const x = d3.scaleLinear()
                .domain([0, 3000])
                .range([ 0, chart_width ]);
            this.chart.append("g")
                .call(d3.axisBottom(x))
                .attr("transform", "translate(" + margin.left + "," + (chart_height + margin.top) + ")");

            const y = d3.scaleBand()
                .range([chart_height, 0])
                .domain(chartData. map(function(d) { return d.name; }))
                .padding(0.2);
            this.chart.append("g")
                .call(d3.axisLeft(y))
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

            this.chart.selectAll("rect")
                .data(chartData)
                .enter()
                .append("rect")
                    .attr("x", margin.left)
                    .attr("y", function(d) { return y(d.name) })
                    .attr("height", y.bandwidth())
                    .attr("width", function(d) { return x(d.amount)})
                    .attr("fill", fill)
                    .on('mouseover', function(){
                        d3.select(this).attr('fill', function() {return highlightFill; });
                    })
                    .on('mouseout', function(){
                        d3.select(this).attr('fill', function() {return fill; });
                    });
            console.log("done")

        }
    }
}

</script>
