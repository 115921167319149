<template>
    <ChipListCRUD
        v-model="value"
        itemName="Category"
        v-on:saveItem="saveItem"
        :enableDelete="enableDelete"
        :defaultItem="defaultItem"
    >
        <template v-slot:form="slotProps">
            <v-container>
                <v-row>
                    <!-- TODO: NEED rules / validation for these form inputs!! -->
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                        <v-text-field
                            v-model="slotProps.editedItem.name"
                            label="Name"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                        <v-text-field
                            v-model="slotProps.editedItem.amount"
                            label="Amount"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                        <v-text-field
                            v-model="slotProps.editedItem.description"
                            label="Description"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </template>

        <template v-slot:deleteTitle="slotProps">
            <div style="text-align:center; width:100%;">
                Are you sure you want to delete<br>"{{ slotProps.editedItem.name }}"?
            </div>
        </template>
    </ChipListCRUD>
</template>

<script>
import ChipListCRUD from '@/components/ChipListCRUD.vue';
import {Action as BudgetCategoryAction} from '@/store/budgetCategory/types'

export default {
    name: "CategoryChipList",
    components: {
        ChipListCRUD,
    },
    data: () => ({
        defaultItem: {
            name: null,
            amount: 0,
            description: null,
            income: false,
        }
    }),
    methods: {
        saveItem: function(event) {
            console.log(event)
            if (!event.item.finpicid) {
                event.item.finpicid = this.finpicid
            }
            if (!event.item.instanceid) {
                event.item.instanceid = this.instanceid
            }
            if (!event.item.budgetid) {
                event.item.budgetid = this.budgetid
            }
            this.$store.dispatch(
                `${[BudgetCategoryAction.UpsertMonthlyCategoryInstance]}`,
                event.item
                ).then(
                () => {
                  this.$emit('change', {type: 'save'})
                }
            )
        },
    },
    computed: {
    },
    props: {
        value: Array,
        instanceid: Number,
        finpicid: Number,
        budgetid: Number,
        enableDelete: {
            type: Boolean,
            default: false
        },
    },
}
</script>
