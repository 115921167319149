<template>
    <div class="budget-category">
        <v-row>
        <h1>Setup Budget Categories</h1>
        </v-row>
        <br />
    <v-row>
      <v-col cols="2" />
      <v-col cols="8">
          <v-row>
          <v-spacer />
        <v-btn-toggle
            v-model="instanceType"
            mandatory
        >
            <v-btn value="template" @click="activateTemplate()">
                Template
            </v-btn>
            <v-btn value="monthly" @click="activateMonth()">
                Monthly
            </v-btn>
        </v-btn-toggle>
          <v-spacer />
          </v-row>
        <br />
        <MonthSelector
            :disabled="instanceType === 'template'"
            :inputMonths="instanceList"
            :activeMonth="activeInstance.instanceid"
            v-on:change="activateInstance"
            v-on:addBlank="newBlankInstance"
            v-on:addTemplate="newTemplateInstance"
        >
        </MonthSelector>
      </v-col>
      <v-col cols="2" />
    </v-row>
    <br>
        <v-divider></v-divider>
        <br>
        <v-row>
            <v-spacer />
            <h2>{{ activeInstanceTitle }}</h2>
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        :disabled="activeInstanceTitle === 'Template'"
                        v-bind="attrs" v-on="on"
                        @click="syncInstanceTemplate"
                    >
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </template>
                <span>
                    Sync plan with the template
                </span>
            </v-tooltip>
            <v-spacer />
        </v-row>
        <br>
        <v-row justify="center">
            <div style="text-align:center">
                Define monthly budget categories below. This drives
                your <br> monthly progress towards your financial goals!
            </div>
        </v-row>
        <br>
        <v-row>
            <v-col cols="6">
                <h4>Undefined</h4>
                <CategoryChipList
                    v-model="undefinedCategoryList"
                    itemName="Category"
                    :enableDelete="false"
                    :instanceid="activeInstance.instanceid"
                    :finpicid="activeFinpic.finpicid"
                    :budgetid="finpicProfileAlt.monthlyBudget"
                    v-if="undefinedCategoryList.length > 0"
                    @change="refreshCategories"
                >
                </CategoryChipList>
                <h5 v-if="undefinedCategoryList.length === 0">None</h5>
                <CategoryInputList
                    v-model="positiveCategoryList"
                    title="Income"
                    :income="true"
                    :instanceid="activeInstance.instanceid"
                    :finpicid="activeFinpic.finpicid"
                    :budgetid="finpicProfileAlt.monthlyBudget"
                    @change="refreshCategories"
                >
                </CategoryInputList>
                <CategoryInputList
                    v-model="negativeCategoryList"
                    title="Expenses"
                    :income="false"
                    :instanceid="activeInstance.instanceid"
                    :finpicid="activeFinpic.finpicid"
                    :budgetid="finpicProfileAlt.monthlyBudget"
                    @change="refreshCategories"
                >
                </CategoryInputList>
            </v-col>
            <v-col cols="6">
                <CategoryChart :categories="negativeCategoryListForChart"></CategoryChart>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CategoryChart from '@/components/CategoryChart.vue'
import CategoryInputList from '@/components/CategoryInputList.vue'
import MonthSelector from '@/components/MonthSelector.vue'
import CategoryChipList from '@/components/CategoryChipList.vue'
import {Action as ProfileAction, Getter as ProfileGetter} from '@/store/profile/types'
import {Action as BudgetCategoryAction, Getter as BudgetCategoryGetter} from '@/store/budgetCategory/types'
import {Action as InstanceAction, Getter as InstanceGetter} from '@/store/instance/types'

export default {
    name: "BudgetCategory",
    metaInfo: {
        title: 'Categories',
    },
    data: () => ({
        instanceType: 'template',
    }),
    methods: {
      syncInstanceTemplate: function() {
          this.$store.dispatch(
              `${[InstanceAction.SyncInstanceTemplate]}`,
              {
                  templateid: this.finpicProfileAlt.templateInstance,
                  instanceid: this.activeInstance.instanceid,
              }
          )
      },
      activateInstance: function(month) {
          this.$store.dispatch(`${[BudgetCategoryAction.FetchMonthlyCategoryList]}`, {instanceid: month.id});
      },
      activateTemplate: function() {
          this.$store.dispatch(`${[BudgetCategoryAction.FetchMonthlyCategoryList]}`, {instancename: 'Template'})
      },
      activateMonth: function() {
          if (this.instanceList.length > 0) {
              // grab the last! (presuming current...)
              this.activateInstance(this.instanceList[this.instanceList.length - 1])
          }
      },
      newBlankInstance: function(input) {
          this.$store.dispatch(
              `${[InstanceAction.CreateInstance]}`,
              {
                  finpicid: this.activeFinpic.finpicid,
                  input_type: 'month',
                  input_date: this.makeDateString(input.month),
              }
              )
      },
      makeDateString: function(inputDate) {
          const localDate = new Date(inputDate)
          const date = [ localDate.getFullYear(), localDate.getMonth() + 1, localDate.getDate() ]

          if (date[1] < 10) date[1] = `0${date[1]}`
          if (date[2] < 10) date[2] = `0${date[2]}`
          console.log(date)

          return date.join("-")
      },
      newTemplateInstance: function(input) {
          this.$store.dispatch(
              `${[InstanceAction.CreateInstanceFromTemplate]}`,
              {
                  finpicid: this.activeFinpic.finpicid,
                  input_type: 'month',
                  input_date: this.makeDateString(input.month),
                  templateid: this.finpicProfileAlt.templateInstance,
              }
          )
      },
      refreshCategories: function() {
        this.$store.dispatch(`${[BudgetCategoryAction.FetchMonthlyCategoryList]}`, {instanceid: this.activeInstance.instanceid})
      },
    },
    computed: {
        monthlyCategoryList() {
          return this.$store.getters[`${[BudgetCategoryGetter.GetMonthlyCategoryList]}`]
        },
        instanceList() {
          const baseList = this.$store.getters[`${[InstanceGetter.GetMonthlyInstanceList]}`]
          const out = baseList.map(
              (inst) => {
                  return {id: inst.instanceid, date: new Date(inst.effective + ' 00:00:00')}
              }
          );
          return(out)
        },
        activeInstance() {
          return this.$store.getters[`${[InstanceGetter.GetActiveInstance]}`]
        },
        activeInstanceTitle() {
            if (this.activeInstance.name === 'Template') {
                return 'Template'
            } else {
                // TODO: protect against null and format
                return this.activeInstance.effective
            }
        },
        activeFinpic() {
          let item = this.$store.getters[`${[ProfileGetter.getActiveFinpic]}`]
          console.log(item)
          return item
        },
        finpicProfileAlt() {
          return this.$store.getters[`${[ProfileGetter.getFinpicProfileAlt]}`]
        },
        undefinedCategoryList() {
            return this.monthlyCategoryList.filter(
                (x) => typeof(x.amount) === 'object'
            )
        },
        positiveCategoryList() {
            return this.monthlyCategoryList.filter(
                (x) => x.income && typeof(x.amount) === 'number'
            )
        },
        negativeCategoryList() {
            return this.monthlyCategoryList.filter(
                (x) => !x.income && typeof(x.amount) === 'number'
            )
        },
      negativeCategoryListForChart() {
        let prep = this.negativeCategoryList
        return prep.map((item) => {item.amount = Math.abs(item.amount); return item})
      },
    },
  // maybe computed: mapState(['positiveCategoryList', 'negativeCategoryList'])
    mounted() {
      this.$store.dispatch(`${[ProfileAction.loadUserProfile]}`).then(() => {
        this.$store.dispatch(`${[ProfileAction.loadFinpicProfile]}`, {finpicid: this.activeFinpic.finpicid})
        this.$store.dispatch(`${[BudgetCategoryAction.FetchMonthlyCategoryList]}`, {instancename: 'Template'});
        this.$store.dispatch(`${[InstanceAction.FetchMonthlyInstanceList]}`, {finpicid: this.activeFinpic.finpicid})
      })
    },
    components: {
        CategoryChart,
        CategoryInputList,
        CategoryChipList,
        MonthSelector,
    }
}
</script>
