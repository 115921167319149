<template>
    <div>
        <v-chip-group 
        >
            <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <slot name="form" v-bind:editedItem="editedItem">
                            This is a form for input or editing
                        </slot>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="close">
                            Cancel
                        </v-btn>
                        <v-btn color="primary" text @click="save">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px" v-if="enableDelete">
                <v-card>
                    <v-card-title class="headline">
                        <slot name="deleteTitle" v-bind:editedItem="editedItem">
                            Are you sure you want to delete this {{ itemName }}?
                        </slot>
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="primary" text @click="deleteItemConfirm">Yes</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-chip
                v-for="item in value"
                :key="item.budgetcategoryid"
                @click="editItem(item)"
            >
                <div v-if="item">{{ item.name }}<v-icon small @click=deleteItem(item) v-if="enableDelete">mdi-delete</v-icon></div>
            </v-chip>
        </v-chip-group>
    </div>
</template>

<script>
import _ from "lodash"
export default {
    name: "ChipListCRUD",
    data: () => ({
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {name: null, amount: 0, description: null},
    }),
    props: {
        value: Array,
        title: String,
        headers: Array,
        defaultItem: Object,
        itemName: String,
        enableDelete: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New ' + this.itemName : 'Edit ' + this.itemName
        }
    },

    created() {
        this.initialize()
    },

    watch: {
        dialog: function(val) {
            val || this.close()
        },
        dialogDelete: function(val) {
            val || this.closeDelete()
        },
    },

    methods: {
        initialize: function() {
            this.editedItem = _.cloneDeep(this.defaultItem);
        },
        editItem(item) {
            this.editedIndex = this.value.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        // TODO: delete does not seem to trigger properly (it fires after edit...)
        deleteItem(item) {
            this.editedIndex = this.value.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            this.$emit(
                'deleteItem', 
                {index: this.editedIndex, item: this.editedItem},
            )
            this.closeDelete()
        },
        close() {
            // why does this fire twice...?
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = _.cloneDeep(this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            // why does this fire twice...?
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = _.cloneDeep(this.defaultItem)
                this.editedIndex = -1
            })
        },
        save() {
            this.$emit(
                'saveItem',
                {index: this.editedIndex, item: this.editedItem},
            )
            this.close()
        },
    }
}
</script>
